import * as React from "react";
import InstagramIcon from "@mui/icons-material/Instagram";
import { NavBarProps } from "../App";
import {
  Box,
  Button,
  Tooltip,
  Grid,
  CardMedia,
  Typography,
  useMediaQuery,
} from "@mui/material";

import mainImg from "../images/main.jpg";
import logo from "../images/logos/whiteLogo.png";
import { ScreenResolution } from "./common";
import NavBar from "./navBar";

export default function Header(props: NavBarProps) {
  const isExtraSmallScreen = useMediaQuery(ScreenResolution.EXTRA_SMALL);
  const isSmallScreen = useMediaQuery(ScreenResolution.SMALL);
  const isMediumScreen = useMediaQuery(ScreenResolution.MEDIUM);

  const defaultParams = {
    width: "100vw",
    height: "96vh",
  };

  const getResponsiveLayout = () => {
    const props = {
      logoHeight: 100,
      logoSubFontSize: "large",
    };
    if (isMediumScreen) {
      props.logoHeight = 70;
      props.logoSubFontSize = "medium";
    }
    if (isSmallScreen) {
      props.logoHeight = 40;
      props.logoSubFontSize = "small";
    }
    if (isExtraSmallScreen) {
      props.logoHeight = 30;
      props.logoSubFontSize = "small";
    }

    return props;
  };

  const { logoHeight, logoSubFontSize } = getResponsiveLayout();

  return (
    <Box>
      <CardMedia sx={{ ...defaultParams }} image={mainImg}>
        <NavBar {...props} />

        <Grid
          justifyContent="flex-start"
          justifyItems="flex-start"
          alignContent="space-around"
          sx={{
            ...defaultParams,
            display: "grid",
            backgroundColor: "#231f1f42",
            padding: "8vh 16vw",
            color: "white",
          }}
        >
          <Grid
            className='headerLogo'
            container
            flexDirection="column"
            alignItems="flex-start"
            sx={{ gap: "20px" }}
          >
            <CardMedia
              
              component="img"
              height={logoHeight}
              sx={{
                width: "fit-content",
                objectFit: "contain",
                paddingTop: "15vh",
              }}
              image={logo}
            />
            <Typography
              fontWeight="400"
              letterSpacing="1em"
              fontSize={logoSubFontSize}
              variant="body1"
              align="left"
            >
              Dolní Věstonice
            </Typography>
          </Grid>

          <Button className='headerInstagramLogo' target="_blank" href="https://www.instagram.com/sklepkafe/">
            <Tooltip title="Instagram">
              <InstagramIcon fontSize="large" sx={{ color: "white" }} />
            </Tooltip>
          </Button>
        </Grid>
      </CardMedia>
    </Box>
  );
}

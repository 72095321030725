import { Typography, Grid, useMediaQuery } from "@mui/material";
import { styled } from "@mui/material/styles";
import { ScreenResolution } from "./common";

type Props = {
  variant: "h3" | "h5" | "body1";
  width: string;
};

export default function NoData() {
  const isSmallScreen = useMediaQuery(ScreenResolution.SMALL);
  const isMediumScreen = useMediaQuery(ScreenResolution.MEDIUM);

  function getResponsiveLayout(): Props {
    const props: Props = {
      variant: "h3",
      width: "50%",
    };
    if (isMediumScreen) {
      props.variant = "h5";
      props.width = "70%";
    }
    if (isSmallScreen) {
      props.variant = "body1";
      props.width = "100%";
    }

    return props;
  }

  const { variant, width } = getResponsiveLayout();

  return (
    <DividerContainer container>
      <Typography
        variant={variant}
        align="center"
        sx={{
          width,
          letterSpacing: 5,
          fontWeight: 600,
        }}
      >
        Momentálně nejsou žádné data
      </Typography>
      <Typography
        variant='body1'
        align="left"
        sx={{
          letterSpacing: 5,
          fontWeight: 600,
        }}
      >
        Načítám...
      </Typography>
    </DividerContainer>
  );
}

const DividerContainer = styled(Grid)(({ theme }) => ({
  backgroundColor: theme.palette.info.main,
  color: theme.palette.primary.main,
  padding: "10vw",
  flexDirection: 'column',
  gap: 60,
  alignContent: 'center',
  alignItems: 'center',
  height: '96vh',
}));

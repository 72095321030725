import { useState } from "react";
import { styled } from "@mui/material/styles";
import LoginIcon from "@mui/icons-material/Login";
import LogoutIcon from "@mui/icons-material/Logout";
import { Typography, Grid, CardMedia, Tooltip, Button } from "@mui/material";

import logoSmall from "../images/logos/simpleLogoWhiteSmall.png";
import { Auth, FooterProps } from "../App";

interface LoginProps {
  onAuthenticate: (loginName: string, password: string) => Auth;
}

export default function Footer({
  isAdmin,
  onAuthenticate,
  onLogout,
}: FooterProps) {
  const [isLogin, setIsLogin] = useState(false);

  const handleLogout = () => {
    onLogout();
    setIsLogin(false);
  };

  return (
    <FooterLayout container>
      <Typography variant="caption">
        © Sklep Kafe | Dolní Věstonice | {new Date().getFullYear()}
      </Typography>
      <button
        style={{ backgroundColor: "transparent", border: "none" }}
        onDoubleClick={() => setIsLogin(!isLogin)}
      >
        <CardMedia sx={{ width: 24, height: 24 }} image={logoSmall} />
      </button>
      {isLogin && !isAdmin && <Login onAuthenticate={onAuthenticate} />}
      {isAdmin && (
        <Tooltip title="Odhlásit">
          <Button
            color="info"
            variant="outlined"
            onClick={() => handleLogout()}
          >
            <LogoutIcon fontSize="small" />
          </Button>
        </Tooltip>
      )}
    </FooterLayout>
  );
}

function Login({ onAuthenticate }: LoginProps) {
  const [loginName, setloginName] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [error, setError] = useState<string>("");

  const handleSubmit = () => {
    const { errorMessage } = onAuthenticate(loginName, password);
    if (errorMessage) {
      setError(errorMessage);
    }
  };

  return (
    <Grid
      className="footerLogin"
      container
      wrap="nowrap"
      sx={{ gap: 1, width: "max-content" }}
    >
      <input
        required
        id="login-name"
        name="login-name"
        value={loginName}
        placeholder="Přihlašovací jméno"
        onChange={(e) => setloginName(e.target.value)}
      />
      <input
        required
        id="login-password"
        name="login-password"
        type="password"
        value={password}
        placeholder="Heslo"
        onChange={(e) => setPassword(e.target.value)}
      />
      <Tooltip title="Přihlásit">
        <Button
          size="small"
          color="info"
          variant="outlined"
          onClick={() => handleSubmit()}
        >
          <LoginIcon fontSize="small" />
        </Button>
      </Tooltip>
      <Typography color="error" variant="subtitle2">
        {error}
      </Typography>
    </Grid>
  );
}

const FooterLayout = styled(Grid)(({ theme }) => ({
  backgroundColor: theme.palette.primary.light,
  color: theme.palette.primary.contrastText,
  padding: "1vw",
  justifyContent: "center",
  alignItems: "center",
  gap: 4,
}));

import React, { Fragment } from "react";
import CloseIcon from "@mui/icons-material/Close";
import MenuItemContainer from "./menuItemContainer";
import WineItemContainer from "./wineItemContainer";
import logo from "../images/logos/whiteLogoSmall.png";
import simpleLogo from "../images/logos/simpleLogoWhiteSmall.png";
import { styled } from "@mui/material/styles";
import {
  DetailMenuProps,
  MenuItemLayout,
  MenuLayoutType,
  menuTitleProps,
  MenuTitles,
  ScreenResolution,
} from "./common";
import {
  Button,
  Typography,
  Grid,
  CardMedia,
  useMediaQuery,
  AppBar,
  Toolbar,
  Divider,
} from "@mui/material";
import { useGetList } from '../features/useGetList';
import NoData from "./noData";

export default function MenuDetail({
  handleMenu,
  wineListDiv,
}: DetailMenuProps) {

  const { list, loading } = useGetList()

  const isSmallScreen = useMediaQuery(ScreenResolution.SMALL);
  const isMediumScreen = useMediaQuery(ScreenResolution.MEDIUM);

  const menuProps: MenuLayoutType = {
    gridTemplateColumns: isSmallScreen ? "1fr" : "1fr 1fr",
  };

  if (loading) {
    return <NoData />
  }

  return (
    <MenuContainer container justifyContent="center" alignContent="flex-end">
      <MenuLayout container>
        <AppBar position="fixed">
          <StyledToolbar>
            {isSmallScreen ? (
              <CardMedia sx={{ width: 36, height: 36 }} image={simpleLogo} />
            ) : (
              <CardMedia sx={{ width: 220, height: 36 }} image={logo} />
            )}
            <Button color="info" onClick={() => handleMenu()}>
              <CloseIcon fontSize="large" />
            </Button>
          </StyledToolbar>
        </AppBar>
        {list.map(({ title, listItems }) => (
          <Fragment key={title}>
            <Grid
              container
              alignItems="center"
              direction="column"
              sx={{
                width: "80vw",
                marginTop: 16,
              }}
            >
              <MenuTitle
                id={title}
                ref={title === MenuTitles.wine ? wineListDiv : null}
              >
                {title}
              </MenuTitle>
              <Divider flexItem variant="middle" />
            </Grid>
            <Menu {...menuProps}>
              {listItems.map((props) => (
                <Grid
                  key={props.title}
                  container
                  direction="column"
                  alignContent="center"
                  sx={{ marginTop: 1 }}
                >
                  <Typography
                    sx={{
                      ...menuTitleProps,
                      py: 5,
                      textAlign: isMediumScreen ? "center" : "left",
                    }}
                  >
                    {props.title}
                  </Typography>
                  <MenuItemLayout {...menuItemLayoutProps}>
                    {title === MenuTitles.wine
                      ? props.items.map((item) => (
                          <WineItemContainer key={item.name} {...item} />
                        ))
                      : props.items.map((item) => (
                          <MenuItemContainer key={item.name} {...item} />
                        ))}
                  </MenuItemLayout>
                </Grid>
              ))}
            </Menu>
          </Fragment>
        ))}
      </MenuLayout>
    </MenuContainer>
  );
}

export const menuItemLayoutProps: MenuLayoutType = {
  gridTemplateColumns: "1fr",
  columnGap: "1vh",
  rowGap: "30px",
};

const MenuContainer = styled(Grid)(() => ({
  width: "100vw",
  height: "100vh",
  padding: "2px",
  position: "fixed",
  zIndex: 1101,
  backgroundColor: "#00000080",
}));

const MenuLayout = styled(Grid)(() => ({
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "center",
  height: "100%",
  backgroundColor: "#fff",
  zIndex: 3,
  overflow: "scroll",
  flexWrap: "nowrap",
  paddingBottom: "100px",
}));

const Menu = styled(Grid)(({ gridTemplateColumns }: MenuLayoutType) => ({
  display: "grid",
  justifyItems: "center",
  gridTemplateColumns,
  gap: 50,
  width: "100%",
}));

const MenuTitle = styled(Typography)(() => ({
  fontWeight: 300,
  fontSize: "35px",
  letterSpacing: "0.2em",
}));

const StyledToolbar = styled(Toolbar)(() => ({
  alignItems: "center",
  justifyContent: "space-between",
}));

import logo from "../images/logos/whiteLogoSmall.png";
import simpleLogo from '../images/logos/simpleLogoWhiteSmall.png';
import { styled } from "@mui/material/styles";
import { NavBarProps } from "../App";
import {
  AppBar,
  Toolbar,
  CardMedia,
  useMediaQuery,
} from "@mui/material";
import { ScreenResolution } from "./common";
import NavbarMenu from "./navbarMenu";

export default function NavBar(props: NavBarProps) {
  const isSmallScreen = useMediaQuery(ScreenResolution.SMALL);
  const isMediumScreen = useMediaQuery(ScreenResolution.MEDIUM);

  return (
    <AppBar
      className='navBar'
      position="fixed"
      elevation={4}
      color="primary"
      sx={{ alignItems: "center" }}
    >
      <StyledToolbar sx={{ flexDirection: isSmallScreen ? 'row-reverse' : 'row'}}>
        {
          isMediumScreen ?
          <CardMedia sx={{ width: 36, height: 36 }} image={simpleLogo} />
          :
          <CardMedia sx={{ width: 220, height: 36 }} image={logo} />
        }
        <NavbarMenu {...props} />
      </StyledToolbar>
    </AppBar>
  );
}

const StyledToolbar = styled(Toolbar)(() => ({
  alignItems: "center",
  justifyContent: "space-between",
  width: "80vw",
  // Override media queries injected by theme.mixins.toolbar
  "@media all": {
    minHeight: 70,
  },
}));

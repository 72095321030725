import { CSSProperties } from "react";
import {
  MenuItem,
  WineListItemType,
  styledParagraph,
  getPrice,
  ScreenResolution,
  getMenuItemLayout,
} from "./common";
import {
  Typography,
  Grid,
  Divider,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import { Icon } from "@iconify/react";

export default function WineItemContainer({
  name,
  price,
  priceTwo,
  description,
}: WineListItemType) {
  const isSmallScreen = useMediaQuery(ScreenResolution.SMALL);
  const isMediumScreen = useMediaQuery(ScreenResolution.MEDIUM);

  return (
    <Grid container direction="column" alignItems="flex-start">
      <MenuItem {...getMenuItemLayout({ isSmallScreen, isMediumScreen })}>
        <Typography sx={{ ...styledParagraph }}>{name}</Typography>
        <Tooltip title="1 dcl">
          <Typography sx={{ ...styledParagraph, whiteSpace: "nowrap" }}>
            <Icon
              icon="emojione-monotone:wine-glass"
              inline={true}
              width="15"
            />{" "}
            {getPrice(price)}
          </Typography>
        </Tooltip>
      </MenuItem>
      <Divider flexItem />
      <MenuItem {...getMenuItemLayout({ isSmallScreen, isMediumScreen })}>
        <Typography
          variant="caption"
          sx={{ ...descriptionParagaph, textAlign: "left" }}
        >
          {description}
        </Typography>
        {priceTwo && (
          <Tooltip title="Celá lahev - 7dcl">
            <Typography
              variant="caption"
              sx={{
                letterSpacing: "0.2em",
                fontWeight: 400,
                fontSize: 14,
                textAlign: "right",
                whiteSpace: "nowrap",
              }}
            >
              <Icon icon="fa-solid:wine-bottle" inline={true} />{" "}
              {getPrice(priceTwo)}
            </Typography>
          </Tooltip>
        )}
      </MenuItem>
    </Grid>
  );
}

const descriptionParagaph: CSSProperties = {
  fontWeight: 300,
  letterSpacing: "0.2em",
};

import {
  getMenuItemLayout,
  getPrice,
  MenuItem,
  MenuItemType,
  ScreenResolution,
  styledParagraph,
} from "./common";
import { Typography, Grid, Divider, useMediaQuery } from "@mui/material";
import { CSSProperties } from "react";

export default function MenuItemContainer({
  name,
  price,
  description,
  descriptionTwo,
}: MenuItemType) {
  const isSmallScreen = useMediaQuery(ScreenResolution.SMALL);
  const isMediumScreen = useMediaQuery(ScreenResolution.MEDIUM);

  return (
    <Grid container direction="column" alignItems="flex-start">
      <MenuItem {...getMenuItemLayout({ isSmallScreen, isMediumScreen })}>
        <Typography sx={{ ...styledParagraph }}>{name}</Typography>
        <Typography sx={{ ...styledParagraph }}>{getPrice(price)}</Typography>
      </MenuItem>
      {description && (
        <>
          <Divider flexItem />
          <MenuItem {...getMenuItemLayout({ isSmallScreen, isMediumScreen })}>
            <Typography
              variant="caption"
              sx={{ ...descriptionParagaph, textAlign: "left" }}
            >
              {description}
            </Typography>
            {descriptionTwo && (
              <Typography
                variant="caption"
                sx={{ ...descriptionParagaph, textAlign: "right" }}
              >
                {descriptionTwo}
              </Typography>
            )}
          </MenuItem>
        </>
      )}
    </Grid>
  );
}

const descriptionParagaph: CSSProperties = {
  fontWeight: 300,
  letterSpacing: "0.2em",
};

import { Grid } from "@mui/material";
import { styled } from "@mui/material/styles";

export default function Divider() {
  /*
  const isSmallScreen = useMediaQuery(ScreenResolution.SMALL);
  const isMediumScreen = useMediaQuery(ScreenResolution.MEDIUM);
 

  type Props = {
    variant: "h3" | "h5" | "body1";
    width: string;
  };

  function getResponsiveLayout(): Props {
    const props: Props = {
      variant: "h3",
      width: "50%",
    };
    if (isMediumScreen) {
      props.variant = "h5";
      props.width = "70%";
    }
    if (isSmallScreen) {
      props.variant = "body1";
      props.width = "100%";
    }

    return props;
  }

  const { variant, width } = getResponsiveLayout();
  
  */

  return (
    <DividerContainer container>
      {/*
      <Typography
        variant={variant}
        align="left"
        sx={{
          width,
          letterSpacing: 5,
          fontWeight: 600,
        }}
      >
        Ochutnejte u nás výběrovou kávu z Pouzdřan, oblastní vína a jiné
        regionální dobroty.
      </Typography>
        */}
    </DividerContainer>
  );
}

const DividerContainer = styled(Grid)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  padding: "10vw",
}));

import * as React from "react";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import { Grid, Link, Typography, useMediaQuery } from "@mui/material";
import { ScreenResolution, styledParagraph } from "./common";

import tonic from "../images/imageList/tonicCoffee.jpg";
import toasts from "../images/imageList/toasts.jpg";
import doorOpen from "../images/imageList/doorOpen.jpg";
import bar from "../images/imageList/bar.jpg";
import kava from "../images/imageList/kava.jpg";
import yoghurt from "../images/imageList/yoghurt.jpg";

export default function StandardImageList() {
  const isSmallScreen = useMediaQuery(ScreenResolution.SMALL);
  const isMediumScreen = useMediaQuery(ScreenResolution.MEDIUM);

  const getResponsiveLayout = () => {
    const props = {
      width: "70vw",
      padding: "50px",
    };
    if (isMediumScreen) {
      props.width = "90vw";
      props.padding = "20px";
    }
    if (isSmallScreen) {
      props.width = "96vw";
      props.padding = "2vw";
    }

    return props;
  };

  const { width, padding } = getResponsiveLayout();

  const columns = isMediumScreen ? 2 : 3;
  const listGap = isSmallScreen ? 2 : 20;

  return (
    <Grid
      container
      alignItems="center"
      direction="column"
      sx={{ padding, gap: 10, background: "white" }}
    >
      <ImageList gap={listGap} cols={columns} sx={{ width }}>
        {itemData.map((item) => (
          <ImageListItem key={item.img}>
            <img
              src={`${item.img}?w=164&h=164&fit=crop&auto=format`}
              srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
              alt={item.title}
              loading="lazy"
            />
          </ImageListItem>
        ))}
      </ImageList>
      <Typography
        sx={{ ...styledParagraph, fontSize: "1.5rem", alignSelf: "flex-start" }}
      >
        Sledujte nás na Instagramu{" "}
        {
          <Link
            color="secondary"
            underline="hover"
            target="_blank"
            href="https://www.instagram.com/sklepkafe/"
          >
            #SKLEP_KAFE
          </Link>
        }
      </Typography>
    </Grid>
  );
}

const itemData = [
  {
    img: toasts,
    title: "Breakfast",
  },
  {
    img: doorOpen,
    title: "Door open",
  },
  {
    img: tonic,
    title: "Esspreso Tonic",
  },
  {
    img: yoghurt,
    title: "Jogurt",
  },
  {
    img: bar,
    title: "Bar",
  },
  {
    img: kava,
    title: "Coffee",
  },
];

import { useMemo, useEffect, useState } from "react";
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";
import { useMediaQuery } from "@mui/material";
import { ScreenResolution } from "./common";

export default function Map() {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  });

  if (!isLoaded) return <div>Loading...</div>;
  return <MapMemo />;
}

function MapMemo() {
  const [index, setIndex] = useState(1);
  const center = useMemo(() => ({ lat: 48.88778817, lng: 16.64857918 }), []);
  const isMapOverlapping = useMediaQuery(ScreenResolution.MAP);

  useEffect(() => {
    setIndex(getIndex());
  }, []);

  return (
    <GoogleMap
      zoom={12}
      center={center}
      mapContainerStyle={{
        width: "35vw",
        height: "400px",
        minWidth: isMapOverlapping ? "100vw" : "400px",
      }}
    >
      <Marker
        key={index}
        position={center}
        onClick={() =>
          window.open(
            "https://www.google.com/maps/dir//Doln%C3%AD+V%C4%9Bstonice+29,+691+29+Doln%C3%AD+V%C4%9Bstonice/@48.8877516,16.6462091,15z/data=!4m8!4m7!1m0!1m5!1m1!1s0x4712cc27124dc60f:0xdd3dc0a1fea6f2b5!2m2!1d16.6485538!2d48.8878026"
          )
        }
      />
    </GoogleMap>
  );
}

function getIndex() {
  return Math.floor(Math.random() * 10000);
}

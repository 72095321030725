import axios from "axios";
import { useEffect, useState } from "react";
import { TimeItem } from '../components/contact';

interface OpenHours {
  _id: string;
  openHours: Array<TimeItem>
};


export function useGetOpenHours() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>("");
  const [openHoursId, setOpenHoursId] = useState<string>("");
  const [openHours, setOpenHours] = useState<Array<TimeItem>>([]);

  function fetchData() {
    setLoading(true);
    return new Promise<Array<OpenHours>>((resolve) => {
      axios
        .get('/.netlify/functions/openHours')
        .then((res) => {
          resolve(res.data);
          setLoading(false);
        })
        .catch((error) => {
          setError(error.message);
          setLoading(false);
        });
    });
  }

  async function loadData() {
    const data = await fetchData();
    setOpenHoursId(data[0]._id)
    setOpenHours(data[0].openHours);
  }

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { openHours, setOpenHours, openHoursId, loading, error };
}

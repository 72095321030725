import { useState } from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuIcon from '@mui/icons-material/Menu';
import { ActionItem } from './navbarMenu';
import { ListItemIcon, ListItemText } from '@mui/material';

interface Props {
  actionItems: Array<ActionItem>
}

export default function NavbarDropdown({
  actionItems
}: Props) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
       <MenuIcon color='info' fontSize='large' />
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {
          actionItems.map(({ title, icon, handleClick }) => (
            <MenuItem
              key={title}
              onClick={() => {
                handleClose();
                handleClick();
              }}>
              <ListItemIcon>
                {icon}
              </ListItemIcon>
              <ListItemText>{title}</ListItemText>
            </MenuItem>
          ))
        }
      </Menu>
    </div>
  );
}
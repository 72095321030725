import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";
import { MutableRefObject } from "react";
import { ItemValue, ListType } from "./editMenuModal";

export const getPrice = (price: number) => `${price},-`;

export const getMenuItemLayout = ({
  isSmallScreen,
  isMediumScreen,
}: ResponsivityScreen) => {
  const props: MenuItemlayout = {
    width: "30vw",
  };
  if (isMediumScreen) {
    props.width = "40vw";
  }
  if (isSmallScreen) {
    props.width = "90vw";
  }

  return props;
};

export enum MenuTitles {
  wine = "Vinný lístek",
  menu = "Nápojový a jídelní lístek",
}

export enum ScreenResolution {
  EXTRA_SMALL = "(max-width:300px)",
  SMALL = "(max-width:550px)",
  MEDIUM_SMALL = "(max-width:750px)",
  MEDIUM = "(max-width:1050px)",
  MAP = "(max-width:1350px)",
}

export type MenuType = ExtendedMenu | ExtendedWineList;

export type MenuList<T> = {
  title: string;
  listItems: Array<T>;
};

export type ResponsivityScreen = {
  isSmallScreen: boolean;
  isMediumScreen: boolean;
};

export type MenuLayoutType = {
  gridTemplateColumns: string;
  columnGap?: string;
  rowGap?: string;
};

export type MenuItemlayout = {
  width?: string;
};

export type MenuItemType = {
  name: string;
  price: number;
  description?: string;
  descriptionTwo?: string;
};

export type ExtendedMenu = {
  title: string;
  items: Array<MenuItemType>;
};

export type WineListItemType = {
  name: string;
  price: number;
  priceTwo?: number;
  description: string;
};

export type ExtendedWineList = {
  title: string;
  items: Array<WineListItemType>;
};

export interface EditWineListItem extends WineListItemType {
  index: number;
  itemindex: number;
  onRemoveItem: (type: ListType, index: number, itemindex: number) => void;
  onMoveItem: (
    type: ListType,
    goesUp: boolean,
    index: number,
    itemindex: number
  ) => void;
  onItemChange: (
    e: string,
    type: ListType,
    value: ItemValue,
    index: number,
    itemindex: number
  ) => void;
}

export interface EditMenuListItem extends MenuItemType {
  index: number;
  itemindex: number;
  onRemoveItem: (type: ListType, index: number, itemindex: number) => void;
  onMoveItem: (
    type: ListType,
    goesUp: boolean,
    index: number,
    itemindex: number
  ) => void;
  onItemChange: (
    e: string,
    type: ListType,
    value: ItemValue,
    index: number,
    itemindex: number
  ) => void;
}

export interface MenuProps {
  handleMenu: () => void;
}

export interface DetailMenuProps extends MenuProps {
  wineListDiv: MutableRefObject<any>;
}

export const styledParagraph = {
  letterSpacing: "0.2em",
  fontSize: "1rem",
  fontWeight: 400,
  textAlign: "left",
};

export const menuTitleProps = {
  fontWeight: 400,
  fontSize: "2rem",
  letterSpacing: "0.1em",
};

export const MenuItemLayout = styled(Box)(
  ({ gridTemplateColumns, columnGap, rowGap }: MenuLayoutType) => ({
    display: "grid",
    flexDirection: "row",
    justifyItems: "center",
    justifyContent: "space-evenly",
    alignItems: "start",
    alignSelf: "center",
    alignContent: "space-evenly",
    gridTemplateColumns,
    gridTemplateRows: "auto",
    columnGap: columnGap ?? "10vw",
    rowGap: rowGap ?? "12vh",
  })
);

export const MenuItem = styled(Box)(({ width }: MenuItemlayout) => ({
  width: width,
  display: "flex",
  justifyContent: "space-between",
  flexDirection: "row",
  gap: 10,
}));

import React, { useState } from "react";
import {
  Typography,
  Grid,
  useMediaQuery,
  Divider,
  TextField,
  Button,
  Tooltip,
  Snackbar,
  Alert,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  ExtendedMenu,
  ExtendedWineList,
  getMenuItemLayout,
  MenuItemLayout,
  MenuItemType,
  MenuLayoutType,
  MenuTitles,
  ScreenResolution,
  WineListItemType,
} from "./common";
import { menuItemLayoutProps } from "./menuDetail";
import DeleteIcon from "@mui/icons-material/Delete";
import DoneIcon from "@mui/icons-material/Done";
import RefreshIcon from "@mui/icons-material/Refresh";
import CloseIcon from "@mui/icons-material/Close";
import WineItemEditContainer from "./wineItemEditContainer";
import MenuItemEditContainer from "./menuItemEditContainer";
import NoData from "./noData";
import axios from "axios";
import { useGetList } from "../features/useGetList";

export enum ListType {
  menuList = 0,
  wineList = 1,
}

export type ItemValue =
  | "name"
  | "price"
  | "priceTwo"
  | "description"
  | "descriptionTwo";

export default function EditMenuModal({ onFormToggle }) {
  const { list: menuList, setList: setMenuList, listId, loading } = useGetList();
  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const isSmallScreen = useMediaQuery(ScreenResolution.SMALL);
  const isMediumScreen = useMediaQuery(ScreenResolution.MEDIUM);

  const menuToPatch = {
    menuList: menuList[ListType.menuList].listItems,
    wineList: menuList[ListType.wineList].listItems,
  }

  const onSubmit = () => {
    axios
      .patch(`/.netlify/functions/menu/${listId}`, menuToPatch)
      .then((res) => {
        setIsSuccess(res.status === 200);
      })
      .catch((error) => {
        console.log(`Error: ${error}`);
        setIsError(!!error);
      });
  };

  const menuProps: MenuLayoutType = {
    gridTemplateColumns: isSmallScreen ? "1fr" : "1fr 1fr",
  };

  const onAddSection = (type: ListType) => {
    const newList = [...menuList];
    const newMenuSection: ExtendedMenu = {
      title: "",
      items: [
        {
          name: "",
          price: 0,
        },
      ],
    };
    const newWineSection: ExtendedWineList = {
      title: "",
      items: [
        {
          name: "",
          price: 0,
          description: "",
        },
      ],
    };
    if (type === ListType.menuList) {
      newList[type].listItems.push(newMenuSection);
    } else {
      newList[type].listItems.push(newWineSection);
    }

    setMenuList(newList);
  };

  const onRemoveSection = (type: ListType, index: number) => {
    const newList = [...menuList];
    newList[type].listItems.splice(index, 1);
    setMenuList(newList);
  };

  const onSectionTitleChange = (e: string, type: ListType, index: number) => {
    const newList = [...menuList];
    newList[type].listItems[index].title = e;
    setMenuList(newList);
  };

  const onItemChange = (
    e: string,
    type: ListType,
    value: ItemValue,
    index: number,
    itemindex: number
  ) => {
    const newList = [...menuList];

    if (type === ListType.wineList) {
      const wineListItems = newList[type].listItems[index] as ExtendedWineList;
      const item = wineListItems.items[itemindex];
      if (value === "name") item.name = e;
      if (value === "description") item.description = e;
      if (value === "price") item.price = parseInt(e);
      if (value === "priceTwo") item.priceTwo = parseInt(e);
      setMenuList(newList);
    }
    if (type === ListType.menuList) {
      const menuListItems = newList[type].listItems[index] as ExtendedMenu;
      const item = menuListItems.items[itemindex];
      if (value === "name") item.name = e;
      if (value === "price") item.price = parseInt(e);
      if (value === "description") item.description = e;
      if (value === "descriptionTwo") item.descriptionTwo = e;
      setMenuList(newList);
    }
  };

  const onAddItem = (type: ListType, index: number) => {
    const newList = [...menuList];
    const newMenuItem: MenuItemType = {
      name: "",
      price: NaN,
    };
    const newWineItem: WineListItemType = {
      name: "",
      price: NaN,
      description: "",
    };

    if (type === ListType.wineList) {
      const wineListItems = newList[type].listItems[index] as ExtendedWineList;
      wineListItems.items.push(newWineItem);
      setMenuList(newList);
    } else {
      const menuListItems = newList[type].listItems[index] as ExtendedMenu;
      menuListItems.items.push(newMenuItem);
      setMenuList(newList);
    }
  };

  const onRemoveItem = (type: ListType, index: number, itemindex: number) => {
    const newList = [...menuList];
    if (type === ListType.wineList) {
      const wineListItems = newList[type].listItems[index] as ExtendedWineList;
      wineListItems.items.splice(itemindex, 1);
      setMenuList(newList);
    } else {
      const menuListItems = newList[type].listItems[index] as ExtendedMenu;
      menuListItems.items.splice(itemindex, 1);
      setMenuList(newList);
    }
  };

  const onMoveItem = (
    type: ListType,
    goesUp: boolean,
    index: number,
    itemindex: number
  ) => {
    if (goesUp && itemindex < 1) {
      return;
    }
    const newList = [...menuList];
    const moveIndex = goesUp ? itemindex - 1 : itemindex + 1;
    if (type === ListType.wineList) {
      const wineListItems = newList[type].listItems[index] as ExtendedWineList;
      if (!goesUp && itemindex === wineListItems.items.length - 1) {
        return;
      }
      const movedItem = wineListItems.items.splice(itemindex, 1);
      wineListItems.items.splice(moveIndex, 0, movedItem[0]);
      setMenuList(newList);
    } else {
      const menuListItems = newList[type].listItems[index] as ExtendedMenu;
      if (!goesUp && itemindex === menuListItems.items.length - 1) {
        return;
      }
      const movedItem = menuListItems.items.splice(itemindex, 1);
      menuListItems.items.splice(moveIndex, 0, movedItem[0]);
      setMenuList(newList);
    }
  };

  if (loading) {
    return <NoData />;
  }

  return (
    <Grid container justifyContent="center" alignContent="flex-end">
      <MenuLayout container>
        {menuList.map(({ title, listItems }, index) => (
          <React.Fragment key={title}>
            <Grid
              key={"main-title-" + index}
              container
              alignItems="center"
              direction="column"
              sx={{
                width: "80vw",
                marginTop: 16,
              }}
            >
              <MenuTitle>{title}</MenuTitle>
              <Divider flexItem variant="middle" />
            </Grid>
            <Menu {...menuProps}>
              {listItems.map((props, index) => (
                <Grid
                  key={"title " + index}
                  container
                  direction="column"
                  alignContent="center"
                  sx={{ marginTop: 1 }}
                >
                  <Grid
                    container
                    wrap="nowrap"
                    sx={{
                      width: getMenuItemLayout({
                        isSmallScreen,
                        isMediumScreen,
                      }),
                    }}
                  >
                    <TextField
                      required
                      id={"section-title-" + index}
                      name={props.title}
                      value={props.title}
                      fullWidth
                      label="Název sekce"
                      variant="filled"
                      onChange={(e) =>
                        onSectionTitleChange(
                          e.target.value,
                          title === MenuTitles.wine
                            ? ListType.wineList
                            : ListType.menuList,
                          index
                        )
                      }
                    />
                    <Tooltip title="Smazat celou sekci">
                      <Button
                        variant="contained"
                        onClick={() =>
                          onRemoveSection(
                            title === MenuTitles.wine
                              ? ListType.wineList
                              : ListType.menuList,
                            index
                          )
                        }
                      >
                        <DeleteIcon />
                      </Button>
                    </Tooltip>
                  </Grid>

                  <MenuItemLayout {...menuItemLayoutProps}>
                    {title === MenuTitles.wine
                      ? props.items.map((item, itemindex) => (
                          <WineItemEditContainer
                            key={"wine-item-" + itemindex}
                            {...{
                              ...item,
                              index,
                              itemindex,
                              onRemoveItem,
                              onMoveItem,
                              onItemChange,
                            }}
                          />
                        ))
                      : props.items.map((item, itemindex) => (
                          <MenuItemEditContainer
                            key={"menu-item-" + itemindex}
                            {...{
                              ...item,
                              index,
                              itemindex,
                              onRemoveItem,
                              onMoveItem,
                              onItemChange,
                            }}
                          />
                        ))}
                    <Button
                      color="secondary"
                      variant="outlined"
                      onClick={() =>
                        onAddItem(
                          title === MenuTitles.wine
                            ? ListType.wineList
                            : ListType.menuList,
                          index
                        )
                      }
                    >
                      Přidat produkt
                    </Button>
                  </MenuItemLayout>
                </Grid>
              ))}
              <Button
                variant="contained"
                sx={{ alignSelf: "center" }}
                onClick={() =>
                  onAddSection(
                    title === MenuTitles.wine
                      ? ListType.wineList
                      : ListType.menuList
                  )
                }
              >
                Přidat sekci
              </Button>
            </Menu>
          </React.Fragment>
        ))}
      </MenuLayout>
      <EditControlsContainer container>
        <Tooltip title="Uložit">
          <Button
            variant="contained"
            color="success"
            onClick={() => onSubmit()}
          >
            <DoneIcon />
          </Button>
        </Tooltip>
        <Tooltip title="Obnovit">
          <Button
            variant="contained"
            color="secondary"
            onClick={() => onFormToggle()}
          >
            <RefreshIcon />
          </Button>
        </Tooltip>
        <Tooltip title="Zavřít bez uložení">
          <Button
            variant="contained"
            color="error"
            onClick={() => onFormToggle()}
          >
            <CloseIcon />
          </Button>
        </Tooltip>
      </EditControlsContainer>
      {getSnackbar(isError, isSuccess)}
    </Grid>
  );
}

const getSnackbar = (isError: boolean, isSuccess: boolean) => {
  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={isError}
        autoHideDuration={6000}
      >
        <Alert severity="error" sx={{ width: 400, justifyContent: "center" }}>
          Neuloženo
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={isSuccess}
        autoHideDuration={6000}
      >
        <Alert severity="success" sx={{ width: 400, justifyContent: "center" }}>
          Uloženo
        </Alert>
      </Snackbar>
    </>
  );
};

const EditControlsContainer = styled(Grid)(() => ({
  flexDirection: "column",
  position: "fixed",
  width: "max-content",
  alignItems: "center",
  gap: "25px",
  right: 0,
  bottom: 0,
  zIndex: 5,
  margin: "7vh 2vw",
}));

const Menu = styled(Grid)(() => ({
  display: "grid",
  justifyItems: "center",
  gridTemplateColumns: "1fr 1fr",
  gap: 50,
  width: "100%",
}));

const MenuLayout = styled(Grid)(() => ({
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "center",
  height: "100%",
  backgroundColor: "#fff",
  zIndex: 3,
  overflow: "scroll",
  flexWrap: "nowrap",
  paddingBottom: "100px",
}));

const MenuTitle = styled(Typography)(() => ({
  fontWeight: 300,
  fontSize: "35px",
  letterSpacing: "0.2em",
}));

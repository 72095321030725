import { useEffect, useState } from "react";
import {
  EditMenuListItem,
  getMenuItemLayout,
  MenuItem,
  ScreenResolution,
} from "./common";
import {
  Grid,
  useMediaQuery,
  TextField,
  Button,
  Tooltip,
  Divider,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { ListType } from "./editMenuModal";

export default function MenuItemEditContainer({
  name,
  price,
  description,
  descriptionTwo,
  index,
  itemindex,
  onRemoveItem,
  onMoveItem,
  onItemChange,
}: EditMenuListItem) {
  const [currentName, setCurrentName] = useState(name);
  const [currentPrice, setCurrentPrice] = useState(price);
  const [currentDescription, setCurrentDescription] = useState(description);
  const [currentDescriptionTwo, setCurrentDescriptionTwo] =
    useState(descriptionTwo);
  const isSmallScreen = useMediaQuery(ScreenResolution.SMALL);
  const isMediumScreen = useMediaQuery(ScreenResolution.MEDIUM);

  const id = itemindex.toString();

  useEffect(() => {
    setCurrentName(name);
    setCurrentPrice(price);
    setCurrentDescription(description);
    setCurrentDescriptionTwo(descriptionTwo);
  }, [name, price, description, descriptionTwo, index, itemindex]);

  return (
    <Grid
      container
      direction="column"
      alignItems="flex-start"
      sx={{ border: "1px solid #231f1f0f" }}
    >
      <MenuItem {...getMenuItemLayout({ isSmallScreen, isMediumScreen })}>
        <TextField
          required
          id={id}
          name={`menu-item-name-${id}`}
          value={currentName}
          fullWidth
          onChange={(e) => setCurrentName(e.target.value)}
          placeholder="Název produktu"
          variant="standard"
          onBlur={() =>
            onItemChange(
              currentName,
              ListType.menuList,
              "name",
              index,
              itemindex
            )
          }
        />
        <TextField
          required
          id={id}
          name={`menu-item-price-${id}`}
          value={currentPrice ? currentPrice.toString() : ""}
          onChange={(e) => setCurrentPrice(parseInt(e.target.value))}
          placeholder="Cena"
          variant="standard"
          onBlur={() =>
            onItemChange(
              currentPrice.toString(),
              ListType.menuList,
              "price",
              index,
              itemindex
            )
          }
        />
      </MenuItem>
      <MenuItem {...getMenuItemLayout({ isSmallScreen, isMediumScreen })}>
        <TextField
          id={id}
          name={`menu-item-description-${id}`}
          value={currentDescription ?? ""}
          placeholder="Informace o produktu"
          fullWidth
          multiline
          variant="standard"
          onChange={(e) => setCurrentDescription(e.target.value)}
          onBlur={() =>
            onItemChange(
              currentDescription,
              ListType.menuList,
              "description",
              index,
              itemindex
            )
          }
        />
        <TextField
          id={id}
          name={`menu-item-description-two-${id}`}
          value={currentDescriptionTwo ?? ""}
          placeholder="Objem"
          variant="standard"
          onChange={(e) => setCurrentDescriptionTwo(e.target.value)}
          onBlur={() =>
            onItemChange(
              currentDescriptionTwo,
              ListType.menuList,
              "descriptionTwo",
              index,
              itemindex
            )
          }
        />
      </MenuItem>
      <Grid container justifyContent="space-between">
        <Grid>
          <Tooltip title="Posunout nahoru">
            <Button
              color="secondary"
              onClick={() =>
                onMoveItem(ListType.menuList, true, index, itemindex)
              }
            >
              <ArrowDropUpIcon fontSize="large" />
            </Button>
          </Tooltip>
          <Tooltip title="Posunout dolů">
            <Button
              color="secondary"
              onClick={() =>
                onMoveItem(ListType.menuList, false, index, itemindex)
              }
            >
              <ArrowDropDownIcon fontSize="large" />
            </Button>
          </Tooltip>
        </Grid>
        <Tooltip title="Smazat produkt">
          <Button
            color="secondary"
            onClick={() => onRemoveItem(ListType.menuList, index, itemindex)}
          >
            <DeleteIcon />
          </Button>
        </Tooltip>
      </Grid>
      <Divider flexItem />
    </Grid>
  );
}

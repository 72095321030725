import React, { MutableRefObject, ReactElement, useState } from "react";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import QueryBuilderOutlinedIcon from "@mui/icons-material/QueryBuilderOutlined";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import SettingsIcon from "@mui/icons-material/Settings";
import Map from "./googleMap";
import { useTheme } from "@mui/material/styles";
import {
  Typography,
  Grid,
  CardMedia,
  Button,
  Tooltip,
  useMediaQuery,
  TextField,
} from "@mui/material";
import { ScreenResolution, styledParagraph } from "./common";
import logoSmall from "../images/logos/logoSmall.png";
import { useGetOpenHours } from "../features/useGetOpenHours";
import axios from "axios";

type ContactItemType = {
  icon: ReactElement;
  value: string;
};
export type TimeItem = {
  title: string;
  value: string;
};

type SocialItem = {
  icon: ReactElement;
  title: string;
  href: string;
};

interface OpeningHoursProps {
  timeItems: Array<TimeItem>;
  isAdmin?: boolean;
  editMode?: boolean;
  setEditMode?: React.Dispatch<React.SetStateAction<boolean>>;
}

interface EditOpeningHoursProps extends OpeningHoursProps {
  openHoursId: string;
  setTimeItems: React.Dispatch<React.SetStateAction<Array<TimeItem>>>;
}

interface Props {
  contactDiv: MutableRefObject<any>;
  isAdmin?: boolean;
}

export default function Contact({ contactDiv, isAdmin }: Props) {
  const {
    openHours: timeItems,
    setOpenHours: setTimeItems,
    openHoursId,
    loading,
  } = useGetOpenHours();
  const [editMode, setEditMode] = useState(false);
  const theme = useTheme();
  const isMapOverlapping = useMediaQuery(ScreenResolution.MAP);

  const getResponsiveLayout = () => {
    const props = {
      outerGridPadding: "50px",
      innerGridPadding: "0px",
    };
    if (isMapOverlapping) {
      props.outerGridPadding = "0px";
      props.innerGridPadding = "50px";
    }

    return props;
  };

  const { innerGridPadding, outerGridPadding } = getResponsiveLayout();

  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems="center"
      sx={{
        backgroundColor: theme.palette.secondary.main,
        padding: outerGridPadding,
      }}
    >
      <Grid
        container
        direction="column"
        justifyContent="space-between"
        sx={{
          width: "max-content",
          minWidth: "280px",
          padding: innerGridPadding,
          gap: "50px",
        }}
      >
        <CardMedia
          ref={contactDiv}
          id="contact"
          sx={{ width: 222, height: 36 }}
          image={logoSmall}
        />
        <Grid container gap={12}>
          {loading ? (
            <Typography>Loading...</Typography>
          ) : (
            <Grid container gap={8} direction="column" width={340}>
              {timeItems.length <= 3 && <Address />}
              {editMode ? (
                <EditOpeningHours
                  timeItems={timeItems}
                  setTimeItems={setTimeItems}
                  setEditMode={setEditMode}
                  openHoursId={openHoursId}
                />
              ) : (
                <OpeningHours
                  timeItems={timeItems}
                  isAdmin={isAdmin}
                  setEditMode={setEditMode}
                />
              )}
            </Grid>
          )}

          <Grid container gap={4} direction="column" width={340}>
            {timeItems.length > 3 && <Address />}
            {contactItems.map((props) => (
              <ContactItem key={props.value} {...props} />
            ))}
            <Grid container height={14} />
            <Grid container sx={{ marginLeft: "-17px" }}>
              {socialItems.map(({ icon, title, href }) => (
                <Button key={title} target="_blank" href={href}>
                  <Tooltip title={title}>{icon}</Tooltip>
                </Button>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Map />
    </Grid>
  );
}

function ContactItem({ icon, value }: ContactItemType) {
  const isSmallScreen = useMediaQuery(ScreenResolution.SMALL);

  return (
    <Grid
      container
      wrap="nowrap"
      alignItems="center"
      gap={isSmallScreen ? 2 : 6}
    >
      {icon}
      <Typography
        sx={{ ...styledParagraph, fontSize: isSmallScreen && "12px" }}
      >
        {value}
      </Typography>
    </Grid>
  );
}

function EditOpeningHours({
  timeItems,
  setTimeItems,
  setEditMode,
  openHoursId,
}: EditOpeningHoursProps) {
  const isSmallScreen = useMediaQuery(ScreenResolution.SMALL);
  const isMax = timeItems.length >= 7;

  const onSubmit = () => {
    axios
      .patch(`/.netlify/functions/openHours/${openHoursId}`, { openHours: timeItems })
      .then(() => setEditMode(false))
      .catch((error) => {
        console.log(`Error: ${error}`);
      });
  };

  const onAddItem = () => {
    const newTimeItems = [...timeItems];
    const newItem: TimeItem = {
      title: "",
      value: "",
    };

    newTimeItems.push(newItem);
    setTimeItems(newTimeItems);
  };

  const onRemoveItem = (index: number) => {
    const newTimeItems = [...timeItems];
    newTimeItems.splice(index, 1);
    setTimeItems(newTimeItems);
  };

  const onChange = (e: string, index: number, type: "title" | "value") => {
    const newTimeItems = [...timeItems];
    if (type === "title") newTimeItems[index].title = e;
    if (type === "value") newTimeItems[index].value = e;

    setTimeItems(newTimeItems);
  };

  const onCancel = () => {
    setTimeItems(openHours);
    setEditMode(false);
  };

  return (
    <Grid container wrap="nowrap" gap={isSmallScreen ? 2 : 6}>
      <QueryBuilderOutlinedIcon fontSize="large" />
      <Grid container direction="column" gap={1}>
        {timeItems.map(({ title, value }, index) => (
          <Grid
            key={index}
            container
            direction="column"
            sx={{ border: "1px solid #d6852d" }}
          >
            <TextField
              required
              id={"opening-hours-title-" + index}
              name={title}
              value={title}
              fullWidth
              label="Den / Dny"
              variant="filled"
              onChange={(e) => onChange(e.target.value, index, "title")}
            />
            <TextField
              required
              id={"opening-hours-" + index}
              name={value}
              value={value}
              fullWidth
              placeholder="Čas od - do"
              variant="standard"
              onChange={(e) => onChange(e.target.value, index, "value")}
            />
            {!(index === 0) && (
              <Grid alignSelf="flex-end">
                <Tooltip title="Odebrat řádek">
                  <Button
                    size="small"
                    variant="text"
                    onClick={() => onRemoveItem(index)}
                  >
                    <DeleteIcon fontSize="small" />
                  </Button>
                </Tooltip>
              </Grid>
            )}
          </Grid>
        ))}
        <Grid container wrap="nowrap">
          <Tooltip title="Přidat řádek">
            <Button
              disabled={isMax}
              variant="outlined"
              onClick={() => onAddItem()}
            >
              {isMax ? "Lze přidat maximálně 7 polí" : <AddIcon />}
            </Button>
          </Tooltip>
          <Grid container justifyContent="flex-end">
            <Button
              variant="outlined"
              color="success"
              onClick={() => onSubmit()}
            >
              Uložit
            </Button>
            <Button variant="outlined" color="error" onClick={() => onCancel()}>
              Zrušit
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

function OpeningHours({ isAdmin, timeItems, setEditMode }: OpeningHoursProps) {
  const isSmallScreen = useMediaQuery(ScreenResolution.SMALL);

  return (
    <Grid container wrap="nowrap" gap={isSmallScreen ? 2 : 6}>
      <QueryBuilderOutlinedIcon fontSize="large" />
      <Grid container direction="column" gap={1}>
        {timeItems.map(({ title, value }, index) => (
          <Grid key={index} container direction="column">
            <Typography sx={{ ...styledParagraph, fontWeight: 600 }}>
              {title}
            </Typography>
            <Typography sx={{ ...styledParagraph }}>{value}</Typography>
          </Grid>
        ))}
        {isAdmin && (
          <Tooltip title="Přizpůsobit">
            <Button
              sx={{ alignSelf: "flex-start" }}
              variant="outlined"
              onClick={() => setEditMode(true)}
            >
              <SettingsIcon />
            </Button>
          </Tooltip>
        )}
      </Grid>
    </Grid>
  );
}

function Address() {
  const isSmallScreen = useMediaQuery(ScreenResolution.SMALL);

  return (
    <Grid container wrap="nowrap" gap={isSmallScreen ? 2 : 6}>
      <LocationOnOutlinedIcon fontSize="large" />
      <Grid container direction="column" gap={1}>
        <Grid container direction="column">
          <Typography sx={{ ...styledParagraph, fontWeight: 600 }}>
            Dolní Věstonice 29
          </Typography>
          <Typography sx={{ ...styledParagraph }}>
            691 29, Dolní Věstonice
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}

const socialItems: Array<SocialItem> = [
  {
    icon: <InstagramIcon />,
    title: "Instagram",
    href: "https://www.instagram.com/sklepkafe/",
  },
  {
    icon: <FacebookIcon />,
    title: "Facebook",
    href: "https://www.facebook.com/profile.php?id=100083997801017",
  },
];

const contactItems: Array<ContactItemType> = [
  {
    icon: <LocalPhoneOutlinedIcon fontSize="large" />,
    value: "+420 603 576 670",
  },
  {
    icon: <EmailOutlinedIcon fontSize="large" />,
    value: "v.macanova@seznam.cz",
  },
];

const openHours: Array<TimeItem> = [
  {
    title: "Pondělí - Pátek",
    value: "11:00 - 18:00",
  },
  {
    title: "Sobota - Neděle",
    value: "9:00 - 22:00",
  },
];

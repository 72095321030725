import axios from "axios";
import { useEffect, useState } from "react";
import {
  ExtendedMenu,
  ExtendedWineList,
  MenuList,
  MenuTitles,
  MenuType,
} from "../components/common";
import { ListType } from "../components/editMenuModal";

export type ExtendedList = {
  _id: string;
  menuList: Array<ExtendedMenu>;
  wineList: Array<ExtendedWineList>;
};

const defaultList: Array<MenuList<MenuType>> = [
  {
    title: MenuTitles.menu,
    listItems: [],
  },
  {
    title: MenuTitles.wine,
    listItems: [],
  },
];

export function useGetList() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>("");
  const [listId, setListId] = useState<string>("");
  const [list, setList] = useState<Array<MenuList<MenuType>>>(defaultList);

  function fetchData() {
    setLoading(true);
    return new Promise<Array<ExtendedList>>((resolve) => {
      axios
        .get(`/.netlify/functions/menu`)
        .then((res) => {
          console.log(res.data)
          resolve(res.data);
          setLoading(false);
        })
        .catch((error) => {
          setError(error.message);
          setLoading(false);
        });
    });
  }

  async function loadData() {
    const data = await fetchData();
    defaultList[ListType.menuList].listItems = data[0].menuList
    defaultList[ListType.wineList].listItems = data[0].wineList

    console.log(data)

    setListId(data[0]._id)
    setList(defaultList);
  }

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { list, setList, listId, loading, error };
}

// import MenuBookIcon from "@mui/icons-material/MenuBook";
import ContactPhoneIcon from "@mui/icons-material/ContactPhone";
// import WineBarIcon from "@mui/icons-material/WineBar";
import SettingsIcon from "@mui/icons-material/Settings";
import NavbarDropdown from "./navbarDropdown";
import { useTheme } from "@mui/material/styles";
import { NavBarProps } from "../App";
import { Button, Typography, Grid, useMediaQuery } from "@mui/material";
import { ScreenResolution } from "./common";

export interface ActionItem {
  title: string;
  icon: JSX.Element;
  handleClick: () => void;
}

export default function NavbarMenu({
  onContactFocus,
  onFormToggle,
  isAdmin,
}: NavBarProps) {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(ScreenResolution.SMALL);

  /**
   * Disabled on request of the owner
   * 
   *   const handleWineList = () => {
   *     handleMenu();
   *     onWineListFocus();
   *   };
   */

  const defaultActionItems: Array<ActionItem> = [
    // Disabled on request of the owner
    /*
    {
      title: "Vinný lístek",
      icon: <WineBarIcon />,
      handleClick: handleWineList,
    },
    {
      title: "Lístek",
      icon: <MenuBookIcon />,
      handleClick: handleMenu,
    },
    */
    {
      title: "Kontakt",
      icon: <ContactPhoneIcon />,
      handleClick: onContactFocus,
    },
  ];

  const adminAtionItems = [
    ...defaultActionItems,
    {
      title: "Upravit lístek",
      icon: <SettingsIcon />,
      handleClick: onFormToggle,
    },
  ];

  const actionItems = isAdmin ? adminAtionItems : defaultActionItems

  if (isSmallScreen) {
    return <NavbarDropdown actionItems={actionItems} />;
  }
  return (
    <Grid sx={{ display: "flex", justifyContent: "space-between" }}>
      {actionItems.map(({ title, icon, handleClick }) => (
        <Button
          key={title}
          onClick={() => handleClick()}
          sx={{ my: 1, mx: 1.5, color: theme.palette.primary.contrastText }}
        >
          <Grid container direction="column" alignItems="center">
            {icon}
            <Typography variant="caption">{title}</Typography>
          </Grid>
        </Button>
      ))}
    </Grid>
  );
}

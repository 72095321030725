import { useEffect, useState } from "react";
import {
  MenuItem,
  ScreenResolution,
  getMenuItemLayout,
  EditWineListItem,
} from "./common";
import {
  Grid,
  Tooltip,
  useMediaQuery,
  TextField,
  Button,
  Divider,
} from "@mui/material";
import { Icon } from "@iconify/react";
import { ListType } from "./editMenuModal";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

export default function WineItemEditContainer({
  name,
  price,
  priceTwo,
  description,
  index,
  itemindex,
  onRemoveItem,
  onMoveItem,
  onItemChange,
}: EditWineListItem) {
  const [currentName, setCurrentName] = useState(name);
  const [currentPrice, setCurrentPrice] = useState(price);
  const [currentPriceTwo, setCurrentPriceTwo] = useState(priceTwo);
  const [currentDescription, setCurrentDescription] = useState(description);
  const isSmallScreen = useMediaQuery(ScreenResolution.SMALL);
  const isMediumScreen = useMediaQuery(ScreenResolution.MEDIUM);

  const id = itemindex.toString();

  useEffect(() => {
    setCurrentName(name);
    setCurrentPrice(price);
    setCurrentDescription(description);
    setCurrentPriceTwo(priceTwo);
  }, [name, price, description, priceTwo, index, itemindex]);

  return (
    <Grid
      container
      direction="column"
      alignItems="flex-start"
      sx={{ border: "1px solid #231f1f0f" }}
    >
      <MenuItem
        sx={{ alignItems: "center " }}
        {...getMenuItemLayout({ isSmallScreen, isMediumScreen })}
      >
        <TextField
          required
          id={id}
          name={`wine-item-name-${id}`}
          value={currentName}
          fullWidth
          placeholder="Název produktu"
          variant="standard"
          onChange={(e) => setCurrentName(e.target.value)}
          onBlur={() =>
            onItemChange(
              currentName,
              ListType.wineList,
              "name",
              index,
              itemindex
            )
          }
        />
        <Tooltip title="Cena za 1dcl">
          <Icon icon="emojione-monotone:wine-glass" inline={true} width="15" />
        </Tooltip>
        <TextField
          required
          id={id}
          name={`wine-item-price-${id}`}
          value={currentPrice ? currentPrice.toString() : ""}
          placeholder="Cena za 1dcl"
          variant="standard"
          onChange={(e) => setCurrentPrice(parseInt(e.target.value))}
          onBlur={() =>
            onItemChange(
              currentPrice.toString(),
              ListType.wineList,
              "price",
              index,
              itemindex
            )
          }
        />
      </MenuItem>
      <MenuItem
        sx={{ alignItems: "center " }}
        {...getMenuItemLayout({ isSmallScreen, isMediumScreen })}
      >
        <TextField
          required
          id={id}
          name={`wine-item-description-${id}`}
          value={currentDescription}
          placeholder="Informace o produktu"
          fullWidth
          multiline
          variant="standard"
          onChange={(e) => setCurrentDescription(e.target.value)}
          onBlur={() =>
            onItemChange(
              currentDescription,
              ListType.wineList,
              "description",
              index,
              itemindex
            )
          }
        />
        <Tooltip title="Cena za lahev - 7dcl">
          <Icon icon="fa-solid:wine-bottle" inline={true} />
        </Tooltip>
        <TextField
          required
          id={id}
          name={`wine-item-price-two-${id}`}
          value={currentPriceTwo ? currentPriceTwo.toString() : ""}
          placeholder="Cena za lahev"
          variant="standard"
          onChange={(e) => setCurrentPriceTwo(parseInt(e.target.value))}
          onBlur={() =>
            onItemChange(
              currentPriceTwo.toString(),
              ListType.wineList,
              "priceTwo",
              index,
              itemindex
            )
          }
        />
      </MenuItem>
      <Grid container justifyContent="space-between">
        <Grid>
          <Tooltip title="Posunout nahoru">
            <Button
              color="secondary"
              onClick={() =>
                onMoveItem(ListType.wineList, true, index, itemindex)
              }
            >
              <ArrowDropUpIcon fontSize="large" />
            </Button>
          </Tooltip>
          <Tooltip
            title="Posunout dolů"
            onClick={() =>
              onMoveItem(ListType.wineList, false, index, itemindex)
            }
          >
            <Button color="secondary">
              <ArrowDropDownIcon fontSize="large" />
            </Button>
          </Tooltip>
        </Grid>
        <Tooltip title="Smazat produkt">
          <Button
            color="secondary"
            onClick={() => onRemoveItem(ListType.wineList, index, itemindex)}
          >
            <DeleteIcon />
          </Button>
        </Tooltip>
      </Grid>
      <Divider flexItem />
    </Grid>
  );
}

import { useState, useRef } from "react";
import "./App.css";
import Header from "./components/header";
import MenuDetail from "./components/menuDetail";
import Divider from "./components/divider";
import StandardImageList from "./components/imageList";
import Contact from "./components/contact";
import Footer from "./components/footer";
import EditMenuModal from "./components/editMenuModal";
import { createTheme, ThemeProvider } from "@mui/material";
import NavBar from "./components/navBar";
import { MenuTitles } from "./components/common";

export type NavBarProps = {
  handleMenu: () => void;
  onContactFocus: () => void;
  onWineListFocus: () => void;
  onFormToggle: () => void;
  isAdmin: boolean;
};

export type FooterProps = {
  onAuthenticate: (loginName: string, password: string) => Auth;
  onLogout: () => void;
  isAdmin: boolean;
};

export type Auth = {
  errorMessage: string;
};

const theme = createTheme({
  palette: {
    primary: {
      main: "#231f1f",
    },
    secondary: {
      main: "#e78e2e",
    },
    info: {
      main: "#fff",
    },
    tonalOffset: 0.2,
  },
});

export default function App() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isForm, setIsForm] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  const handleMenu = () => setIsMenuOpen(!isMenuOpen);
  const onFormToggle = () => setIsForm(!isForm);
  const onAuthenticate = (loginName: string, password: string): Auth => {
    const auth: Auth = {
      errorMessage: "Špatné přihlašovací jméno nebo heslo.",
    };
    if (loginName === "admin" && password === "sklepkafeadmin") {
      setIsAdmin(true);
      auth.errorMessage = "";
    }

    return auth;
  };
  const onLogout = () => setIsAdmin(false);

  const contactDiv = useRef(null);
  const wineListDiv = useRef(null);

  const onContactFocus = () => {
    waitForElement("contact", () => {
      contactDiv.current.scrollIntoView({ behavior: "smooth" });
    });
  };
  const onWineListFocus = () => {
    waitForElement(MenuTitles.wine, () => {
      wineListDiv.current.scrollIntoView({ behavior: "smooth" });
    });
  };

  const navBarProps: NavBarProps = {
    handleMenu,
    onContactFocus,
    onWineListFocus,
    onFormToggle,
    isAdmin,
  };

  const footerProps: FooterProps = {
    onAuthenticate,
    onLogout,
    isAdmin,
  };

  /*
  const coffeInfoProps: InfoBoxProps = {
    image: coffeImg,
    title: "Rodinná pražírna DOBRÝ KAFE",
    link: 'https://www.dobrykafe.cz',
    isLeft: false,
    description:
      "Kávu zde praží již více než 15 let. S láskou a pokorou. Cíle jsou stále stejné – přinášet Vám kávu prvotřídní kvality která pohladí všechny Vaše smysly.",
    backgroundColor: theme.palette.primary.main,
  };
  */

  if (isForm) {
    return (
      <ThemeProvider theme={theme}>
        <NavBar {...navBarProps} />
        <EditMenuModal onFormToggle={onFormToggle} />
        <Footer {...footerProps} />
      </ThemeProvider>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      {isMenuOpen && (
        <MenuDetail handleMenu={handleMenu} wineListDiv={wineListDiv} />
      )}
      <Header {...navBarProps} />
      <Divider />
      {
        /**
         * Disabled on request of the owner
         * <Menu handleMenu={handleMenu} />
         * <InfoBox {...coffeInfoProps} />
         * <Carousel />
         */
      }
      <StandardImageList />
      <Contact contactDiv={contactDiv} isAdmin={isAdmin} />
      
      <Footer {...footerProps} />
    </ThemeProvider>
  );
}

export function waitForElement(id: string, callback: () => void): void {
  const interval = setInterval(() => {
    if (document.getElementById(id)) {
      clearInterval(interval);
      callback();
    }
  }, 100);
}
